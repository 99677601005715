import React from "react"
import { css } from "@emotion/core"
import Layout from "../components/Layout"
import InfoColumn from "../components/InfoColumn"
import {
  Arrow,
  Image,
  AboutBox,
  SocialGrid,
  Animate,
} from "../components/common"

import { useWindowWidth } from "../hooks/useWindowWidth"

import UsImage from "../images/about/us.jpg"
import MeetingImage from "../images/about/meeting.jpg"

const AboutPage = () => {
  const width = useWindowWidth()

  return (
    <Layout>
      <div
        css={theme => css`
          margin: ${theme.constants.pageMargin};

          @media (max-width: 1200px) {
            margin: ${theme.constants.mediumpageMargin};
          }

          @media (max-width: 1000px) {
            margin: 0px;
          }
        `}
      >
        <div
          css={css`
            @media (max-width: 1000px) {
              padding: 0 20px;
            }
          `}
        >
          <h1
            css={theme => css`
              font-size: ${theme.constants.largeFontSize}px;
              font-weight: ${theme.constants.demi};
              margin-bottom: 50px;
              position: relative;

              @media (max-width: 880px) {
                font-size: ${theme.constants.mediumFontSize}px;
              }
            `}
          >
            Who are we?
          </h1>
          <p
            css={theme => css`
              font-size: ${theme.constants.mediumFontSize}px;
              font-weight: ${theme.constants.extraLight};
              margin-bottom: 100px;
              width: 45%;

              @media (max-width: 1000px) {
                width: 100%;
              }

              @media (max-width: 880px) {
                font-size: ${theme.constants.smallFontSize}px;
                margin-bottom: 50px;
              }
            `}
          >
            We create fast, reliable mobile and web applications, custom built
            for your business.
          </p>
        </div>
        <div
          css={css`
            width: 100%;
            height: 1000px;
            position: relative;

            @media (max-width: 1000px) {
              height: inherit;
              padding: 0 20px;
            }
          `}
        >
          {width >= 1000 && (
            <Animate type="reveal" cascade right distance="20px">
              <Image
                src={UsImage}
                css={theme => css`
                  position: absolute;
                  right: 0;
                  box-shadow: ${theme.constants.boxShadow};
                  height: 72%;
                `}
              />
            </Animate>
          )}
          <div
            css={css`
              position: absolute;
              text-align: left;
              line-height: 1.7;

              @media (max-width: 1000px) {
                position: relative;
              }
            `}
          >
            <div
              css={theme => css`
                font-size: ${theme.constants.smallFontSize}px;
                font-weight: ${theme.constants.extraLight};
                width: 40%;

                margin-bottom: 70px;

                @media (max-width: 1000px) {
                  width: 100%;
                }

                @media (max-width: 880px) {
                  font-size: ${theme.constants.infoDescriptionTextSize}px;
                  margin-bottom: 30px;
                }
              `}
            >
              We are a software engineering company helping startups and
              enterprises by creating custom software solutions. Our mission is
              to help you grow your business.
            </div>
            <div
              css={theme => css`
                font-size: ${theme.constants.mediumFontSize}px;
                font-weight: ${theme.constants.extraLight};
                color: ${theme.colors.white};
                line-height: 1;
                width: 60%;

                padding: 70px;
                margin-bottom: 80px;

                ${theme.gradients.blue(theme)};

                @media (max-width: 1000px) {
                  width: 100%;
                }

                @media (max-width: 880px) {
                  font-size: ${theme.constants.infoDescriptionTextSize}px;
                  margin-bottom: 30px;
                  padding: 30px;
                }
              `}
            >
              Finding the right solution will help you grow your business.
            </div>
            <div
              css={theme => css`
                font-size: ${theme.constants.smallFontSize}px;
                font-weight: ${theme.constants.extraLight};

                width: 42%;

                @media (max-width: 1000px) {
                  width: 100%;
                }

                @media (max-width: 880px) {
                  font-size: ${theme.constants.infoDescriptionTextSize}px;
                  margin-bottom: 30px;
                }
              `}
            >
              Being a start-up isn’t easy but we understand the head-scratches
              you may be facing. We can give you the tools that allow you to
              focus on your goal.
            </div>
          </div>
        </div>

        <Arrow top={200} right={50} />
        <Arrow flip top={1420} left={50} />
      </div>
      <div
        css={css`
          position: relative;
          padding-top: 200px;
          margin-top: 50px;

          @media (max-width: 1000px) {
            padding-top: 0px;
          }
        `}
      >
        <div
          css={css`
            width: 70%;
            height: 300px;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%);

            @media (max-width: 1000px) {
              width: 100%;
              height: initial;
              position: relative;
              left: 0;
              transform: translate(0);
            }
          `}
        >
          <AboutBox />
        </div>
        <div
          css={theme => css`
            background: url(${MeetingImage});
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            padding: ${theme.constants.pageMargin};
            padding-top: 150px;
            padding-bottom: 150px;

            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
            grid-auto-rows: auto;
            align-items: center;
            grid-column-gap: 140px;
            grid-row-gap: 0px;
            width: 100%;
            align-items: flex-start;

            @media (max-width: 1200px) {
              padding: ${theme.constants.mediumpageMargin};
              padding-top: 150px;
              padding-bottom: 150px;
              grid-column-gap: 50px;
            }

            @media (max-width: 1000px) {
              padding-top: 50px;
              padding-bottom: 50px;
              grid-template-columns: 1fr;
              padding-left: 20px;
              padding-right: 20px;
            }
          `}
        >
          <InfoColumn
            dark
            smallDetail
            title="International know-how"
            detail="We have customers in Atlanta, Chicago, Berlin and Hungary. We know your expectations about response time and quality are high. We won’t let you down!"
          />
          <InfoColumn
            dark
            smallDetail
            title="Personal care"
            detail="We are a small business, you will always talk to a co-founder. And you can bet we will do pretty much everything in our power to satisfy your software development needs."
          />
          <InfoColumn
            dark
            smallDetail
            title="Speed"
            detail="Be the first on the market! Launching your product in time is crucial to be successful. We always deliver on time."
          />
        </div>
      </div>
      <SocialGrid />
    </Layout>
  )
}

export default AboutPage
